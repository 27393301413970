<template>
    <layout>
        <form method="post" @submit.prevent="checkForm">
            <div>
                <div class="d-flex justify-content-between">
                    <h5>Stock Adjustment</h5>
                    <div class="mt-15 d-flex justify-content-between">
                        <router-link to="/inventory" class="btn btn-default btn-sm mr-5">
                            <i class="fa fa-ban"></i>&nbsp; Cancel
                        </router-link>
                        <button type="submit" role="button" class="btn btn-danger btn-sm">
                            <i class="fa fa-save"></i>&nbsp; Submit
                        </button>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="px-5 flex-grow-1">
                        <fieldset class="border rounded" style="height: 100%;">
                            <legend>&nbsp;📋 Details&nbsp;&nbsp;</legend>
                            <div class="px-20 py-10">
                                <div class="form-group form-inline">
                                    <div class="custom-radio">
                                        <input v-model="form.type" type="radio" name="type" id="type-stock-in" value="in">
                                        <label for="type-stock-in">Stock In</label>
                                    </div>
                                    <div class="custom-radio ml-20">
                                        <input v-model="form.type" type="radio" name="type" id="type-stock-out" value="out">
                                        <label for="type-stock-out">Stock Out</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="transaction_date">Date</label>
                                    <input v-model="form.transaction_date" type="date" class="form-control" name="transaction_date" id="transaction_date" placeholder="Date">
                                </div>
                                <div class="form-group">
                                    <label for="employee_id">Employee</label>
                                    <select v-model="form.employee_id" id="employee_id" class="form-control">
                                        <option selected="selected" disabled="disabled" value="0">Select Employee</option>
                                        <option v-for="value in employees" :value="value.id" :key="value.id">{{ value.lastname }}{{(value.lastname)?", ":"" }}{{ value.firstname }} {{ value.middlename }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="remarks">Remarks</label>
                                    <textarea v-model="form.remarks" class="form-control" name="remarks" id="remarks" placeholder="Write a short remarks."></textarea>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="px-5 flex-grow-1">
                        <fieldset class="border rounded" style="height: 100%;">
                            <legend>&nbsp;👕 Items&nbsp;&nbsp;</legend>
                            <div class="px-20 py-10">
                                <div class="form-group">
                                    <label for="item_id">Item</label>
                                    <select v-model="form.item_id" class="form-control" id="item_id" @change="setName">
                                        <option value="0" selected="selected" disabled="disabled">Select Item</option>
                                        <option v-for="value in items" :key="value.id" :value="value.id">{{ value.name }} ({{ value.size }})</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <input type="hidden" v-model="form.item_name" class="form-control" id="item_name">
                                    <input type="hidden" v-model="form.balance_stock" class="form-control" id="balance_stock">
                                </div>
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input type="number" v-model="form.quantity" class="form-control" id="quantity" placeholder="Quantity">
                                </div>
                                <div class="form-group float-right">
                                    <button type="button" class="btn btn-secondary btn-sm" @click="addItem"> Add</button>
                                </div>

                                <div class="mb-10">
                                    <table class="table table-hover table-outer-bordered font-size-12">
                                        <thead>
                                            <tr>
                                                <th width="50%" class="text-center">👕 ITEM</th>
                                                <th width="30%" class="text-center">🛒 QUANTITY</th>
                                                <th width="20%" class="text-center"> ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in arraylist" :key="item.item_id">
                                                <td class="text-center">{{ item.item_name }}</td>
                                                <td class="text-center">{{ item.quantity}}</td>
                                                <td class="text-right">
                                                    <a @click="deleteItem(item.item_id)" class="btn btn-sm btn-danger btn-square"><i class="fa fa-minus"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </form>
    </layout>
</template>

<script>
import Toast from "../../mixins/Toast";

export default {
    emits: [ 'added' ],
    mixins: [ Toast ],
    data() {
        return {
            form: {
                type: 'in',
                transaction_date: new Date().toISOString().substr(0, 10),
                employee_id: 0,
                remarks: '',
                item_id: 0,
                item_name: '',
                quantity: '',
                balance_stock: 0,
                formArrayList: []
            },
            employees: this.getEmployees(),
            items: this.getItems(),
            arraylist: [],
        }
    },
    methods: {
        addItem(){
            if(this.form.item_id !== '' && this.form.item_id !== 0 && this.form.quantity !== ''){
                if(this.form.type === 'out'){
                    if(this.form.quantity > this.form.balance_stock){
                        var pcs = (this.form.balance_stock > 1) ? "pcs" : "pc";
                        this.ToastError(`Quantity exceeds the current balance! Balance Stock: ${ this.form.balance_stock }  ${ pcs } only.`);
                        return false;
                    }
                }

                if(this.checkIdExists(this.form.item_id)){
                    // get previous quantity of an item
                    var previousqty = this.arraylist.find((e)=>e.item_id === this.form.item_id).quantity;
                    // remove existing item
                    this.deleteItem(this.form.item_id);
                    // add the new item
                    this.arraylist.unshift({
                        item_id: this.form.item_id,
                        item_name: this.form.item_name,
                        quantity: (parseInt(this.form.quantity) + parseInt(previousqty))
                    });
                }else{
                    this.arraylist.unshift({
                        item_id: this.form.item_id,
                        item_name: this.form.item_name,
                        quantity: this.form.quantity
                    });
                }

            }else{
                this.ToastError(`Some fields are missing!`);
            }
        },

        deleteItem(item_id){
            this.arraylist = this.arraylist.filter((e)=>e.item_id !== item_id);
        },

        checkIdExists(item_id){
            for(var i=0; i<this.arraylist.length; i++){
                if(this.arraylist[i].item_id == item_id){
                    return true;
                }
            }
            return false;
        },

        setName(){
            this.$http.get(`/api/item/get/${this.form.item_id}`).then((response) => {
                this.form.item_name = response.data.name;
                this.checkQuantity();
            });
        },

        checkQuantity(){
            this.$http.get(`/api/inventory/getByItemId/${this.form.item_id}`).then((response) => {
                this.form.balance_stock = response.data.balance_stock;
            });
        },

        getEmployees(){
            this.$http.get(`/api/employee/employeelist`).then((response) => {
                this.employees = response.data.list;
            });
        },

        getItems(){
            this.$http.get(`/api/item/itemlist`).then((response) => {
                this.items = response.data.list;
            });
        },

        submitForm() {
            // submit form
            this.$http.post('/api/inventory/adjust', this.form).then((response) => {
                this.ToastSuccess(response.data);
                this.resetForm();
                this.$emit('added');
            });
        },

        checkForm: function () {
            // append array of items to form
            this.form.formArrayList = this.arraylist;

            if (this.form.type !== '' && (this.form.employee_id !== '' || this.form.employee_id !== 0) && this.form.remarks !== '' && this.form.formArrayList !== null) {
                this.submitForm();
                return;
            }else{
                this.ToastError("Some fields are missing!");
            }
        },

        resetForm() {
            this.form.type = 'in';
            this.form.transaction_date = '';
            this.form.employee_id = 0;
            this.form.remarks = '';
            this.form.arraylist = '';
            this.form.item_id = 0;
            this.form.quantity = '';
            this.arraylist = [];
        },
    }
}
</script>